// UserContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';
import Cookies from 'js-cookie';
import Api from "./Api";
import { v4 as uuidv4 } from 'uuid';
import { useLocation } from 'react-router-dom';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const location = useLocation();
    const [userId, setUserId] = useState(Cookies.get('userId') || null);
    const [userData, setUserData] = useState(null);
    const [sessionId, setSessionId] = useState(userId ? userId : uuidv4()); // merge sessionId and userId?
    const [experimentId, setExperimentId] = useState(null);

    useEffect(() => {
        const loadUserData = async () => {
            if (userId) {
                const userData = await Api.readCrud("users", "userId", userId);
                setUserData(userData);
            } else {
                setUserData({});
            }
        };

        loadUserData();
    }, [userId]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const id = params.get('experimentId');
        if (id) {
            setExperimentId(id);
        }
    }, [location.search]);

    return (
        <UserContext.Provider value={{ userId, setUserId, userData, setUserData, sessionId, setSessionId, experimentId }}>
            {children}
        </UserContext.Provider>
    );
}

export const useUser = () => useContext(UserContext);
