import Config from "./Config.js";

class Api {
	// Deprecated. Use readCrudByQuery instead
	static async readCrud(collection, key, value) {
		const url = Config.API_URL + `?op=crud&collection=${collection}&key=${key}&value=${value}`;
		const response = await fetch(url);
		return (await response.json())[0];
	}

	// Returns array
	static async readCrudByQuery(collection, query) {
		const queryString = encodeURIComponent(JSON.stringify(query));
		const url = Config.API_URL + `?op=crud&collection=${collection}&query=${queryString}`;
		const response = await fetch(url);
		return await response.json();
	}

	static async writeCrud(collection, key, value, data) {
		const url = Config.API_URL + `?op=crud&collection=${collection}&key=${key}&value=${value}`;
		const response = await fetch(url, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(data)
		});
	}

	static async writeFeedback(feedback) {
		const url = Config.API_URL + "?op=feedback";
		const response = await fetch(url, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(feedback)
		});
	}

	static async rewritePodcast(podcast, prompt, model = "3.5") {
		const url = Config.API_URL + `?op=rewritePodcast`;
		const response = await fetch(url, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				"podcast": podcast,
				"prompt": prompt,
				"model": model,
			}),
		});

		return await response.json();
	}

	static async createPodcast(prompt, topic) {
		const url = Config.API_URL + "?op=createPodcast";
		const response = await fetch(url, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				"prompt": prompt,
				"topic": topic,
			}),
		});

		return await response.json();
	}

	static async writeQuizResponse(quizResponse) {
		const url = Config.API_URL + "?op=quizResponse";
		const response = await fetch(url, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(quizResponse)
		});
		return await response.json();
	}

	// TODO: Refactor writeCrud (above)
	static async writeCrud2(collection, data) {
		const url = Config.API_URL + `?op=crud&collection=${collection}`;
		const response = await fetch(url, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(data)
		});
	}

	static async readItinerary(itineraryId, version = null) {
		let url = Config.API_URL + `?op=trip&op2=itinerary&itineraryId=${itineraryId}`;
		if (version) {
			url += `&version=${version}`;
		}
		const response = await fetch(url);
		return await response.json();
	}

	static async createUser(emailAddress) {
		const url = Config.API_URL + "?op=crud&collection=users&key=userId&value=" + emailAddress;
		const response = await fetch(url, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				"userId": emailAddress,
				"emailAddress": emailAddress,
			}),
		});
	}

	static async log(sessionId, action) {
		const url = Config.API_URL + "?op=crud&collection=log";
		const response = await fetch(url, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				"timestamp": new Date().toISOString(),
				"sessionId": sessionId,
				"action": action
			}),
		});
	}

	static async error(message, error = new Error()) {
		console.error(message, error);
		const url = Config.API_URL + "?op=crud&collection=error";
		const response = await fetch(url, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				"timestamp": new Date().toISOString(),
				"message": message,
				"errorMessage": error.message,
				"errorStack": error.stack
			}),
		});
	}

	static async loadUser(userId) {
		return await Api.readCrud("users", "userId", userId);
	}

	static async loadDestinations(userId) {
		const url = Config.API_URL + `?op=trip&op2=destinations&userId=${userId}`;
		const response = await fetch(url);
		return await response.json();
	}
}

export default Api;
