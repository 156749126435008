import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';

const Timer = ({ isRunning }) => {
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    let interval = null;

    if (isRunning) {
      interval = setInterval(() => {
        setSeconds((seconds) => seconds + 1);
      }, 1000);
    } else if (!isRunning && seconds !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isRunning, seconds]);

  return (
    <Typography variant="h4" component="h2" sx={{ fontFamily: 'Monospace', textAlign: 'center' }}>
      {seconds}
    </Typography>
  );
};

export default Timer;
