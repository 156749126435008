// Rating.js

import React from "react";
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import "./Rating.css";
import { useUser } from './UserContext';
import Api from "./Api";

const RatingContainer = styled('div')(({ theme, highlight }) => ({
	boxSizing: 'border-box',
	padding: '10px',
	border: highlight ? `2px solid ${theme.palette.primary.main}` : 'none',
}));

const Rating = ({ page, parentOnClick, rating: ratingProperty }) => {
	const maxStars = 5;
	const { userId, userData } = useUser();
	const [rating, setRating] = React.useState(ratingProperty);

	const onClick = (rating) => { // Move to parent component?
		const feedback = {
			"version": "0.202404230",
			"userId": userId,
			"name": page.name,
			"pageId": page.id,
			"placeId": page.placeId,
			"rating": rating,
			"timestamp": new Date().toISOString()
		};

		setRating(rating);
		parentOnClick(feedback);
	}

	const showRatingInstructions = () => {
		return userData && userData.flags && userData.flags.showRatingInstructions;
	}

	return (
		<RatingContainer highlight={showRatingInstructions()}>
			{showRatingInstructions() && !rating && (
				<Typography variant="body2" color="primary" style={{ textAlign: 'center' }}>
					<b>Rate this page to continue!</b>
					<br />
					We’ll use your input to adjust what we recommend and help you create the perfect travel itinerary
				</Typography>
			)}
			{rating && (
				<Typography variant="body2" color="primary" style={{ textAlign: 'center' }}>
					<b>Your rating:</b>
				</Typography>
			)}
			<div>
				<Box display="flex"             // Ensures the icons are in a row
					justifyContent="space-evenly" // Distributes space evenly around each item
					width="100%"
					paddingTop="16px"  // Adds padding above the stars
					paddingBottom="16px"  // Adds padding below the stars
				>
					{[...Array(maxStars)].map((_, index) => {
						const Icon = rating && index < rating ? StarIcon : StarBorderIcon;
						return (
							< Icon
								key={index}
								color={rating && index < rating ? 'primary' : 'disabled'
								}
								onClick={() => onClick(index + 1)}
								style={{ cursor: 'pointer', fontSize: '3rem' }}
							/>
						);
					})}
				</Box>
			</div>
		</RatingContainer >
	);
};

export default Rating;
