import React, { useState, useEffect } from 'react';

import AudioPlayer from "./AudioPlayer";
import Api from "./Api";

const Audio = () => {
    const [podcasts, setPodcasts] = useState([]);
    let wakeLock = null;

    useEffect(() => {
        const load = async () => {
            const response = await Api.readCrudByQuery("podcasts", {});
            console.log(JSON.stringify(response, null, 2));
            setPodcasts(response);
        };

        load();
    }, []);

    useEffect(() => {
        // Request Wake Lock
        const requestWakeLock = async () => {
            if ('wakeLock' in navigator) {
                try {
                    wakeLock = await navigator.wakeLock.request('screen');
                    console.log('Wake Lock is active');

                    wakeLock.addEventListener('release', () => {
                        console.log('Wake Lock was released');
                    });

                } catch (err) {
                    console.error(`${err.name}, ${err.message}`);
                }
            } else {
                console.log('Wake Lock API is not supported in this browser');
            }
        };

        requestWakeLock();

        // Release Wake Lock when the component unmounts
        return () => {
            wakeLock?.release().then(() => {
                console.log('Wake Lock was released upon component unmount');
                wakeLock = null;
            });
        };
    }, []);

    return (
        <div>
            {podcasts.length > 0 ? (
                <AudioPlayer tracks={podcasts} />
            ) : null}
        </div>
    );
};

export default Audio;
