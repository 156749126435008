import React, { useEffect, useState } from 'react';
import { TextField, Button, Checkbox, FormControlLabel, FormGroup, FormControl, InputLabel, Select, MenuItem, Typography, Box, Card, Radio, RadioGroup } from '@mui/material';
import { useLocation } from 'react-router-dom';
import Api from './Api';
import { useUser } from './UserContext';

function CustomTrip() {
    const experiments = [
        {
            id: "20240503a",
            flags: {
                createAccount: "20240503a"
            }
        },
        {
            id: "20240503b",
            flags: {
                createAccount: "20240503a"
            }
        },
        {
            id: "20240506a",
            flags: {
                showCompanions: true,
            }
        },
        {
            id: "20240506b",
            flags: {
                createAccountHeadline: 0,
                showRebate: true
            }
        },

    ];

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const { userId, sessionId } = useUser();
    const [destination, setDestination] = useState(searchParams.get('destination') || '');
    const [experimentId, setExperimentId] = useState(searchParams.get('experimentId') || '');
    const [companions, setCompanions] = useState('');
    const [preferences, setPreferences] = useState([]);
    const [otherPreference, setOtherPreference] = useState('');
    const [isOtherSelected, setIsOtherSelected] = useState(false);
    const [email, setEmail] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [childrenTravel, setChildrenTravel] = useState('');
    const [childrenAges, setChildrenAges] = useState('');
    const [flags, setFlags] = useState(experiments.find(experiment => experiment.id === experimentId)?.flags || {});

    useEffect(() => {
        Api.log(sessionId, `CustomTrip: experimentId=${experimentId}`);
    }, [userId, sessionId]);

    const handleSubmit = (e) => {
        Api.log(sessionId, `CustomTrip submit: experimentId=${experimentId}`);
        e.preventDefault();
        const customTrip = {
            timestamp: (new Date()).toISOString(),
            destination,
            companions,
            preferences,
            otherPreference,
            email,
            childrenTravel,
            childrenAges
        };
        Api.writeCrud2('customTrips', customTrip);
        setSubmitted(true);
    };

    const handleCompanionChange = (event) => { // The function handleCompanionChange resets the childrenTravel state every time the companion changes. This is appropriate if changing the companion should always reset child-related questions, but it might be unnecessary if the 'family' option remains selected.
        setCompanions(event.target.value);
        setChildrenTravel(''); // Reset children question when changing companion
    };

    const handlePreferenceChange = (event) => {
        const { value, checked } = event.target;
        if (value === 'Other') {
            setIsOtherSelected(checked);
            if (!checked) setOtherPreference(''); // Clear text when "Other" is unchecked
        } else {
            const newPreferences = checked
                ? [...preferences, value]
                : preferences.filter(preference => preference !== value);
            setPreferences(newPreferences);
        }
    };

    if (submitted && flags.createAccount === "20240503a") return (
        <Box sx={{ mt: 3 }}>
            <Typography variant="subtitle1" gutterBottom>Thanks for your interest!</Typography>
            <Typography>We'll be in touch.</Typography>
        </Box>
    );

    if (submitted) {
        return (
            <Box sx={{ mt: 3 }}>
                <Typography variant="subtitle1" gutterBottom>Thanks for your interest!</Typography>
                <Typography>We personalize all our advice, so we need some time to process your request. We’ll email you once your travel recommendations are ready.</Typography>
            </Box>
        );
    }

    if (flags.createAccount === "20240503a") return (
        <Box sx={{ mt: 3, width: '100%' }}>
            <form onSubmit={handleSubmit}>
                <Card variant="outlined" sx={{ p: 2 }}>
                    <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold' }}>Create a free account, or log in.</Typography>
                    <Typography variant="subtitle1" gutterBottom>Gain access to free articles and personalized travel recommendations.</Typography>
                    <TextField
                        type="email"
                        label="Email address"
                        variant="outlined"
                        fullWidth
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        margin="dense"
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                        <Button type="submit" variant="contained" color="primary">Continue</Button>
                    </Box>
                </Card>

            </form>
        </Box>
    );

    return (
        <Box sx={{ mt: 3, width: '100%' }}>
            <form onSubmit={handleSubmit}>
                <Box sx={{ mb: 2 }}>
                    <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold' }}>Where do you want to go?</Typography>
                    <TextField
                        variant="outlined"
                        fullWidth
                        value={destination}
                        onChange={(e) => setDestination(e.target.value)}
                        margin="dense"
                    />
                </Box>

                {flags.showCompanions && (
                    <Box sx={{ mb: 3 }}>
                        <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold' }}>Who’s coming with you?</Typography>
                        <FormControl fullWidth margin="dense">
                            <InputLabel id="companions-label"></InputLabel>
                            <Select
                                labelId="companions-label"
                                value={companions}
                                onChange={handleCompanionChange}
                            >
                                <MenuItem value="solo">Going Solo</MenuItem>
                                <MenuItem value="partner">Partner</MenuItem>
                                <MenuItem value="friends">Friends</MenuItem>
                                <MenuItem value="family">Family</MenuItem>
                            </Select>
                        </FormControl>
                        {companions === 'family' && (
                            <Box sx={{ mt: 2 }}>
                                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Are you traveling with children?</Typography>
                                <RadioGroup
                                    row
                                    value={childrenTravel}
                                    onChange={(e) => setChildrenTravel(e.target.value)}
                                >
                                    <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                    <FormControlLabel value="no" control={<Radio />} label="No" />
                                </RadioGroup>
                                {childrenTravel === 'yes' && (
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        value={childrenAges}
                                        onChange={(e) => setChildrenAges(e.target.value)}
                                        margin="dense"
                                        label="Enter the age of each child (comma separated)"
                                    />
                                )}
                            </Box>
                        )}
                    </Box>
                )}

                <Box sx={{ mb: 3 }}>
                    <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold' }}>How do you want to spend your time?</Typography>
                    <FormControl component="fieldset" margin="dense">
                        <FormGroup>
                            {['Must-See Attractions', 'Great Food', 'Hidden Gems', 'Outdoors', 'Culture', 'Relaxation', 'Adventure and Sports', 'Shopping', 'Nightlife', 'Historical Attractions', 'Other'].map((pref) => (
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={preferences.includes(pref) || (pref === 'Other' && isOtherSelected)}
                                        onChange={handlePreferenceChange}
                                        value={pref}
                                    />}
                                    label={pref}
                                    key={pref}
                                />
                            ))}
                            {isOtherSelected && (
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    rows={3}
                                    value={otherPreference}
                                    onChange={(e) => setOtherPreference(e.target.value)}
                                    margin="dense"
                                    label="Please specify"
                                />
                            )}
                        </FormGroup>
                    </FormControl>
                </Box>

                <Card variant="outlined" sx={{ p: 2 }}>
                    <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold' }}>
                        {(() => {

                            switch (flags.createAccountHeadline) {
                                case 0:
                                    return "Create a free account to view personalized itineraries and deals";
                                default:
                                    return 'Create a free account to view your personalized recommendations';
                            }
                        })()}
                    </Typography>
                    {flags.showRebate && (
                        <Typography variant="subtitle1" color="primary" gutterBottom >New users get a $20 travel rebate!</Typography>
                    )}
                    <TextField
                        type="email"
                        label="Email address"
                        variant="outlined"
                        fullWidth
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        margin="dense"
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                        <Button type="submit" variant="contained" color="primary">Start Exploring</Button>
                    </Box>
                </Card>
            </form>
        </Box>
    );
}

export default CustomTrip;
