
import React, { useEffect } from 'react';
import { Card, CardContent, Typography, Link, List, ListItem, ListItemText, Box, Button, Grid } from '@mui/material';
import { useUser } from './UserContext';
import { useState } from 'react';
import Api from './Api';

const UXR = () => {
    const { userId, userData, sessionId } = useUser();
    const [allowItinerary, setAllowItinerary] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        Api.log(sessionId, window.location.href);
    }, []);

    const handleClick = () => {
        Api.log(sessionId, "uxr signup click");
    };

    return (
        <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12}>
                <Card raised sx={{ maxWidth: 600, margin: 'auto' }}>
                    <CardContent>
                        <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold', lineHeight: 1.4 }}>
                            Get $50 to share your opinion!
                        </Typography>
                        <Typography paragraph sx={{ mt: 2 }}>
                            Love to travel? We’re looking for people to provide feedback on our new travel app!
                        </Typography>
                        <Typography paragraph sx={{ mt: 2 }}>
                            Earn $50 in 25 minutes. Limited spots available!
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Button
                                variant="contained"
                                color="primary"
                                component={Link} // Using Link component to handle the navigation
                                href="https://calendly.com/via-blue/25-minute-feedback-session"
                                onClick={handleClick}
                            >
                                Sign up
                            </Button>
                        </Box>
                    </CardContent>
                </Card>
            </Grid>

        </Grid >
    );
};

export default UXR;
