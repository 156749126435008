
import { useEffect, useState } from 'react';
import Api from './Api';

function EmailDraft() {
    const [emails, setEmails] = useState([]);

    useEffect(() => {
        // load all emails
        const loadEmails = async () => {
            const emails = await Api.readCrudByQuery("emails", {});
            console.log(JSON.stringify(emails)); // TODO: Remove
            setEmails(emails);
        };

        loadEmails();
    }, []);

    // display all emails
    return (
        <>
            {emails && emails.map(email => (
                <>
                    <div>
                        <p>{email.emailAddress}</p>
                        <p>{email.subject}</p>
                        <div
                            dangerouslySetInnerHTML={{ __html: email.body }}
                            style={{
                                fontFamily: 'Arial, sans-serif', // Using generic sans-serif
                                fontSize: '13px',
                                color: '#000000'
                            }}>
                        </div>
                    </div>
                </>
            ))}
        </>
    );
}

export default EmailDraft;