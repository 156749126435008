import React, { useEffect } from 'react';
import { Card, CardContent, Typography, Link, List, ListItem, ListItemText, Box, Button, Grid } from '@mui/material';
import { useUser } from './UserContext';
import { useState } from 'react';
import Api from './Api';

const Offer = () => {
    const { userId, userData, sessionId } = useUser();
    const [allowItinerary, setAllowItinerary] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        Api.log(sessionId, window.location.href);

        const loadFeedback = async () => {
            if (userId) {
                const response = await Api.readCrudByQuery("feedback", { userId });
                const newFeedbackMap = new Map();
                response.forEach(fdb => {
                    newFeedbackMap.set(fdb.placeId, fdb);
                });
                setAllowItinerary(newFeedbackMap.size > 0);
            }
        };

        loadFeedback();
    }, [userId]);

    const onNext = () => {

        Api.writeCrud2('itineraryRequest', {
            userId: userId,
            timestamp: new Date().toISOString(),
        });

        setSubmitted(true);
    };

    return (
        <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12}>
                <Card raised sx={{ maxWidth: 600, margin: 'auto' }}>
                    <CardContent>
                        <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold', lineHeight: 1.4 }}>
                            Get a free custom-made itinerary for your next trip!
                        </Typography>
                        <>
                            {!submitted ? (
                                <>
                                    <Typography variant="subtitle1" component="div" style={{ textAlign: 'center', margin: '20px 0 0px' }}> {/* Adjusted margin for less whitespace */}
                                        Step 1:
                                    </Typography>
                                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            component={Link} // Using Link component to handle the navigation
                                            href="/"
                                            sx={{ textTransform: 'none' }} // Removed mt and mb for closer spacing
                                        >
                                            SELECT ACTIVITIES
                                        </Button>
                                    </Box>
                                    <Typography variant="subtitle1" component="div" style={{ textAlign: 'center', margin: '20px 0 0px' }}> {/* Adjusted margin for less whitespace */}
                                        Step 2:
                                    </Typography>
                                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                            disabled={!allowItinerary}
                                            onClick={onNext}
                                            sx={{ textTransform: 'none' }} // Removed mt and mb for closer spacing
                                        >
                                            REQUEST ITINERARY
                                        </Button>
                                    </Box>
                                </>
                            ) : (
                                <>Thanks! We'll be in touch</>
                            )}
                        </>
                    </CardContent>
                </Card>
            </Grid>

            <Grid item xs={12}>
                <Card raised sx={{ maxWidth: 600, margin: 'auto' }}>
                    <CardContent>
                        <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold', lineHeight: 1.4 }}>
                            Need more help?
                        </Typography>
                        <Typography paragraph sx={{ mt: 2 }}>
                            Our full-service travel agents are ready to assist you with all your travel needs. Whether you need a bespoke travel plan, assistance with bookings, or anything else, we're here to help.
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Button
                                variant="contained"
                                color="primary"
                                component={Link} // Using Link component to handle the navigation
                                href="https://calendly.com/via-blue/welcome"
                                target="_blank"
                                rel="noopener noreferrer"
                                sx={{ mt: 2, mb: 2, textTransform: 'none' }} // Styling options
                            >
                                SCHEDULE A CALL
                            </Button>
                        </Box>
                    </CardContent>
                </Card>
            </Grid>
        </Grid >
    );
};

export default Offer;
