// CardFeed.js

import React, { useState, useEffect } from 'react';
import { useUser } from './UserContext';
import Api from './Api';
import { useNavigate } from 'react-router-dom';

import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ImageSwiper from './ImageSwiper';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';

const OptionalCardActionArea = ({ children, handleClick }) => {
    if (handleClick) {
        return (
            <CardActionArea onClick={handleClick}>
                {children}
            </CardActionArea>
        );
    } else {
        return children;
    }
};

const CardFeed = ({ children, cards, handleClick, photoSource, expandedByDefault = true }) => {
    const navigate = useNavigate();
    const [expanded, setExpanded] = useState(expandedByDefault);

    useEffect(() => {
        console.log("setExpanded: " + expandedByDefault);
        setExpanded(expandedByDefault);
    }, [cards]);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <React.Fragment>
            <Grid container spacing={2}>
                {cards.map(card => (
                    <Grid item key={card.id} xs={12}>
                        <Card key={card.id} sx={{ width: '100%', margin: 'auto' }}>
                            <OptionalCardActionArea handleClick={handleClick ? () => handleClick(card) : null}>
                                {card.image && (
                                    <CardMedia
                                        component="img"
                                        height="200"
                                        image={card.image}
                                    />
                                )}
                                {card.images && (
                                    <ImageSwiper images={card.images}></ImageSwiper>
                                )}
                                {photoSource && (
                                    <Typography variant="body2" style={{
                                        textAlign: 'right',
                                        color: 'gray',
                                        fontSize: '0.6rem',
                                        marginBottom: 0
                                    }}>
                                        {photoSource}
                                    </Typography>
                                )}
                                <CardContent sx={{ padding: 2 }}>
                                    <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold', lineHeight: 1.4 }}>
                                        {card.name}
                                    </Typography>
                                    {!expanded ? (
                                        <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={handleExpandClick}>
                                            <Typography variant="body1" sx={{ textDecoration: 'underline', display: 'flex', alignItems: 'center' }}>
                                                Read again
                                                <IconButton aria-label="show more" sx={{ padding: '0px', marginLeft: '0px' }}> {/* Minimal marginLeft */}
                                                    <ExpandMoreIcon fontSize="medium" /> {/* Adjust size as needed */}
                                                </IconButton>
                                            </Typography>
                                        </Box>
                                    ) : (
                                        <Typography variant="body1" style={{ whiteSpace: 'pre-line' }}>
                                            {card.description}
                                        </Typography>
                                    )}
                                </CardContent>
                                {children}
                            </OptionalCardActionArea>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </React.Fragment >
    );
};

export default CardFeed;