import React from 'react';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import { useUser } from './UserContext';

function Menu() {
    const {userId} = useUser();

    return (
	<div>
	    {userId ? <p>{userId}</p> : <p></p>}
	    <Link to="/profile">Profile</Link>
	</div>
    );
}

export default Menu;
