// ImageSwiper.js

import React, { useState, useRef } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from 'swiper/modules';
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./ImageSlider.css";

const ImageSwiper = ({ images }) => {
    const swiperRef = useRef(null);

    const getCurrentImageUrl = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            const index = swiperRef.current.swiper.activeIndex;
            console.log(`Current Image URL: ${images[index]}`);
        }
    };

    return (
        <>
            <Swiper
                slidesPerView={'auto'}
                spaceBetween={30}
                centeredSlides={true}
                loop={true}
                navigation={true}
                pagination={true}
                modules={[Pagination, Navigation]}
                onSlideChange={getCurrentImageUrl}
                ref={swiperRef}

            >
                {images.map((image, index) => (
                    <SwiperSlide key={index}>
                        <img src={image} />
                    </SwiperSlide>
                ))}
            </Swiper>
        </>
    );
};

export default ImageSwiper;