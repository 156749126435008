// Destination.js

import React, { useState, useEffect, useMemo } from 'react';
import { Typography, Box, Button, AppBar, Toolbar, Container } from '@mui/material';
import Api from "./Api";
import CreateAccount from "./CreateAccount";
import "./ViaBlue.css";
import Rating from "./Rating";
import { v4 as uuidv4 } from 'uuid';
import { useUser } from './UserContext';
import { useNavigate } from 'react-router-dom';
import CardFeed from './CardFeed';
import { te } from 'date-fns/locale';

const Destination = () => { // TODO: Feedback ID when there is no place ID?
    const { userId, userData, sessionId } = useUser();
    const [destinationId, setDestinationId] = useState((new URLSearchParams(window.location.search)).get("destinationId"));
    const [textContent, setTextContent] = useState("");
    const [stateIndex, setStateIndex] = useState(0);
    const [attractionIndex, setAttractionIndex] = useState(0);
    const [feedbackMap, setFeedbackMap] = useState(null);
    const [expanded, setExpanded] = useState(true); // TODO: Remove?
    const navigate = useNavigate();
    const cards = useMemo(() => textContent ? [textContent.attractions[attractionIndex]] : [], [textContent, attractionIndex]);

    const showRating = () => {
        return userData && userData.flags && userData.flags.next === "sentiment";
    };

    useEffect(() => {
        const loadDestination = async () => {
            const response = await Api.readCrud("destinations", "id", destinationId);
            console.log(response); // TODO: Remove
            setTextContent(response);
            Api.log(sessionId, 'loaded');
        };

        const loadFeedback = async () => {
            if (userId) {
                const response = await Api.readCrudByQuery("feedback", { userId });
                const newFeedbackMap = new Map();
                response.forEach(fdb => {
                    newFeedbackMap.set(fdb.placeId, fdb);
                });
                setFeedbackMap(newFeedbackMap);
            }
        };

        Api.log(sessionId, window.location.href); // TODO: Move to App.js?
        Api.log(sessionId, `destinationId=${destinationId}; state=0; attraction=0`);
        loadDestination(); // async
        loadFeedback(); // async
    }, [userId, userData]);

    const onNext = async () => {
        if (feedbackMap && feedbackMap.get(textContent.attractions[attractionIndex].placeId)) {
            Api.writeFeedback(feedbackMap.get(textContent.attractions[attractionIndex].placeId)); // async
        }

        const nextAttractionIndex = attractionIndex + 1;
        if (nextAttractionIndex < textContent.attractions.length) {
            Api.log(sessionId, `destionationId=${destinationId}; state=0; attraction=` + nextAttractionIndex);
            setAttractionIndex(nextAttractionIndex);
        } else {
            if (userId) {
                navigate('/');
            } else {
                // TODO: Redirect to signup page instead
                Api.log(sessionId, "state=1");
                setStateIndex(stateIndex + 1);
            }
        }
    };

    const onRatingClick = (feedback) => {
        const updatedFeedbackMap = new Map(feedbackMap);
        updatedFeedbackMap.set(feedback.placeId, feedback);
        setFeedbackMap(updatedFeedbackMap);
    };

    if (userId && (!userData || !feedbackMap)) {
        return null;
    }

    let content;

    if (stateIndex === 1) {
        content = <CreateAccount />;
    } else {
        content = (
            <React.Fragment>
                {textContent && (
                    <CardFeed
                        cards={cards}
                        expandedByDefault={!feedbackMap.get(textContent.attractions[attractionIndex].placeId)}
                        photoSource="SOURCE: GOOGLE PHOTOS">
                        {showRating() && (
                            <div style={{ marginBottom: '20px' }}>
                                <Rating page={textContent.attractions[attractionIndex]} parentOnClick={onRatingClick} rating={feedbackMap.get(textContent.attractions[attractionIndex].placeId)?.rating} />
                            </div>
                        )}
                        {(!showRating() || feedbackMap.get(textContent.attractions[attractionIndex].placeId)) && (
                            <div style={{ textAlign: 'right' }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    onClick={onNext}
                                    sx={{ marginRight: 2, marginBottom: 2 }}>
                                    Next
                                </Button>
                            </div>
                        )}
                    </CardFeed>
                )}
            </React.Fragment>
        );
    }

    return content;
};

export default Destination;
