import React, { useState, useEffect } from 'react';
import Api from './Api';
import Quiz from './Quiz';
import { useUser } from './UserContext';
import { TextField, Button, List, Link, ListItem, ListItemText, CircularProgress, Typography, Box, Paper } from '@mui/material';
import Timer from './Timer';

// TODO: Save podcast author
const Reader2 = () => {
    const [prompt, setPrompt] = useState('');
    const [topic, setTopic] = useState('');
    const [podcasts, setPodcasts] = useState([]);
    const [selectedPodcastIndex, setSelectedPodcastIndex] = useState(null); // Track the selected podcast index
    const [podcast, setPodcast] = useState(null);
    const [componentState, setComponentState] = useState('CREATE'); // States: 'CREATE', 'WAIT', 'READ', 'QUIZ'
    const { userId, userData } = useUser();

    const examples = [
        "Use lots of cat puns",
        "Talk like a pirate",
        "Use lots of Harry Potter references"
    ];

    useEffect(() => {
        const loadPodcasts = async () => {
            if (userData && userData.podcasts) {
                let podcastFilter = { id: { $in: userData.podcasts } };
                const podcasts = await Api.readCrudByQuery("podcasts", podcastFilter);
                setPodcasts(podcasts);
            }
        };

        loadPodcasts();
    }, [userData]);

    const handleSubmit = async () => {
        setComponentState('WAIT');
        const response = await Api.rewritePodcast(podcast, prompt);
        setPodcast(response);
        setComponentState('READ');
    };

    const podcastTextStyle = {
        whiteSpace: 'pre-wrap'
    };

    const handleQuiz = () => {
        const quiz = {
            "id": userId,
            timestamp: new Date().toISOString(),
            podcastId: podcast.sourceId ? podcast.sourceId : podcast.id,
            questionId: 0,
            correctAnswer: podcast.questions[0].correctAnswer,
        };
        Api.writeCrud("quizzes", "id", userId, quiz);
        console.log('Quiz:', JSON.stringify(quiz, null, 2));
        setComponentState('QUIZ');
    };

    const handleRestart = () => {
        setComponentState('CREATE');
        setSelectedPodcastIndex(null);
        setPodcast(null);
    };

    const indexToLetter = (index) => String.fromCharCode(65 + index);

    const selectPodcast = index => {
        setSelectedPodcastIndex(index); // Update the selected podcast index
        setPodcast(podcasts[index]);
    };

    // Style for the selected podcast
    const selectedStyle = {
        backgroundColor: '#f0f0f0', // A light grey, for example
        cursor: 'pointer',
        padding: '5px'
    };

    // Default style
    const defaultStyle = {
        cursor: 'pointer',
        padding: '5px'
    };

    if (componentState === 'CREATE' && podcasts.length > 0) {
        return (
            <Box sx={{ '& > *': { marginBottom: 4 } }}>
                <Paper elevation={3} sx={{ p: 2 }}>
                    <Typography variant="h6">Select a topic:</Typography>
                    <List sx={{ maxHeight: 300, overflow: 'auto', bgcolor: 'background.paper' }}>
                        {podcasts.map((podcast, index) => (
                            <ListItem
                                button
                                key={podcast.id} // Assuming each podcast has a unique `id`
                                selected={selectedPodcastIndex === index}
                                onClick={() => selectPodcast(index)}
                            >
                                <ListItemText primary={podcast.name} />
                            </ListItem>
                        ))}
                    </List>
                </Paper>

                <Paper elevation={3} sx={{ p: 2 }}>
                    <Typography variant="h6" gutterBottom>
                        Customize the content (optional):
                    </Typography>
                    {examples.map((example, index) => (
                        <Box key={index} sx={{ mt: index === 0 ? 0 : 1 }}> {/* Add spacing between lines but not before the first item */}
                            <Link
                                component="button"
                                variant="body2"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setPrompt(example);
                                }}
                                sx={{ cursor: 'pointer', display: 'block' }} // Display as block to ensure each link is on a new line
                            >
                                {example}
                            </Link>
                        </Box>
                    ))}
                    <TextField
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={4}
                        value={prompt}
                        onChange={(e) => setPrompt(e.target.value)}
                        placeholder="Or enter your own customization"
                        sx={{ mt: 2 }} // Add some margin-top for spacing after the examples
                    />
                </Paper>

                <Button variant="contained" color="primary" onClick={handleSubmit}>
                    Submit
                </Button>
            </Box>
        );
    } else if (componentState === 'WAIT') {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
                <Timer isRunning={true} />
            </Box>
        );
    } else if (componentState === 'READ') {
        return (
            <Box>
                {podcast && (
                    <Typography sx={{ whiteSpace: 'pre-wrap', mt: 2 }}>{podcast.text}</Typography>
                )}
                <Button variant="contained" onClick={handleQuiz} sx={{ mt: 2 }}>
                    Take Quiz
                </Button>
            </Box>
        );
    } else if (componentState === 'QUIZ') {
        return (
            <>
                <Box sx={{ mt: 2 }}>
                    <Typography variant="subtitle1" gutterBottom>
                        {podcast.questions[0].question}
                    </Typography>
                    <List sx={{ padding: 0 }}>
                        {podcast.questions[0].answerOptions.map((option, index) => (
                            <ListItem
                                key={index}
                                sx={{
                                    display: 'list-item',
                                    padding: '4px 0', // Reduces vertical padding to decrease whitespace
                                    marginLeft: 0, // Ensures answers are not indented
                                    '&:before': { // Optionally, remove bullet points if present
                                        content: 'none',
                                    },
                                }}
                            >
                                <ListItemText
                                    primary={`${indexToLetter(index)}. ${option}`}
                                    primaryTypographyProps={{ variant: 'body2' }} // Reduces font size of answers
                                />
                            </ListItem>
                        ))}
                    </List>
                </Box>
                <Quiz quizId={userId} />
                <Button variant="contained" onClick={handleRestart} sx={{ mt: 2 }}>
                    Do Another
                </Button>
            </>
        );
    }

    // Return null if none of the conditions match
    return null;
};

export default Reader2;
