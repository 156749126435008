/*
  TODO:
  - Rename 'timesheet' to 'text'
  - Accept date as URL param
  - Update calculateDurations to work with current date
  */

import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { Box } from '@mui/system';
import Api from './Api.js';
import Config from "./Config";
import { differenceInMinutes, parse, format} from 'date-fns';
import "./ViaBlue.css";
import TimesheetHelper from "./TimesheetHelper";

class Timesheet extends React.Component {

    constructor(props) {
        super(props);

	this.state = {
	    date: "",
	    durations: [],
	    timesheet: ""
	};
    }

    static getDateString() {
	return format(new Date(), 'yyyyMMdd');
    }

    static getTimeString() {
	return format(new Date(), 'HH:mm');
    }
    
    load = async() => {
    }
    
    async componentDidMount() {
	const dateParam = (new URLSearchParams(window.location.search)).get("date");
	const d = dateParam ? dateParam : Timesheet.getDateString();
	const t = await Api.readCrud("timesheet", "date", d);
	console.log("t: " + JSON.stringify(t, null, 2));
	this.setState({
	    date: d,
	    timesheet: t.timesheet,
	    durations: TimesheetHelper.round(TimesheetHelper.calculateDurations(t.timesheet))
	});
    }

    handleChange = async (event) => {
	const timesheet = event.target.value;
	Api.writeCrud("timesheet", "date", this.state.date, { "date": this.state.date, "timesheet": timesheet }); // async
	this.setState({
	    timesheet: timesheet,
	    durations: TimesheetHelper.round(TimesheetHelper.calculateDurations(timesheet))
	}); // async
    }
    
    handleClick = async (letter) => {
	const timesheet = this.state.timesheet + Timesheet.getTimeString() + ": " + letter + "\n";
	
	Api.writeCrud("timesheet", "date", this.state.date, { "date": this.state.date, "timesheet": timesheet }); // async
	this.setState({
	    timesheet: timesheet,
	    durations: TimesheetHelper.round(TimesheetHelper.calculateDurations(timesheet))
	}); // async
    }

    render() {
        return (
	    <div className="mobile-container">
		{this.state.date && this.state.durations && (`"${this.state.date}"` + ": " + JSON.stringify(this.state.durations))}
		<div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
		{this.state.timesheet && (
		    <div>
			<textarea
			    onChange={this.handleChange}
			    value={this.state.timesheet}
			    rows="20"
			    style={{ width: '100%', resize: 'none' }}
			>
			</textarea>
		    </div>
		)}
		<Box sx={{ 
			 display: 'flex', 
			 justifyContent: 'center', 
			 '& > :not(style)': { m: 1 },
			 paddingBottom: '10px'
		     }}>
                    <Button variant="contained" sx={{ width: 50, height: 50, fontSize: '1.5rem' }} onClick={() => this.handleClick('C')}>C</Button>
                    <Button variant="contained" sx={{ width: 50, height: 50, fontSize: '1.5rem' }} onClick={() => this.handleClick('H')}>H</Button>
                    <Button variant="contained" sx={{ width: 50, height: 50, fontSize: '1.5rem' }} onClick={() => this.handleClick('F')}>F</Button>
                    <Button variant="contained" sx={{ width: 50, height: 50, fontSize: '1.5rem' }} onClick={() => this.handleClick('T')}>T</Button>
		</Box>
	    </div>
		</div>
		);
    }
}

export default Timesheet;
