export default class TaskQueue {
    constructor() {
        this.promiseChain = Promise.resolve();
    }

    enqueue(task) {
        this.promiseChain = this.promiseChain.then(() => task());
        return this.promiseChain;
    }
}
