import React from 'react';
import { useState } from 'react';
import { Card, CardContent, Typography, Link, List, ListItem, ListItemText, Box, Button, Grid } from '@mui/material';
import Api from './Api';

function Unsubscribe() {
    const [unsubscribed, setUnsubscribed] = useState(false);
    const queryParams = new URLSearchParams(window.location.search);
    const loginToken = queryParams.get('loginToken');

    const handleUnsubscribe = () => {
        // Add logic to handle the unsubscribe action here
        setUnsubscribed(true);
        Api.writeCrud2('unsubscribe', {
            loginToken: loginToken,
            timestamp: new Date().toISOString(),
        });
        // Redirect or update the UI after unsubscribing
    };

    return (
        <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12}>
                <Card raised sx={{ maxWidth: 600, margin: 'auto' }}>
                    <CardContent>
                        <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold', lineHeight: 1.4 }}>
                            Manage Your Email Preferences
                        </Typography>
                        {unsubscribed ? (
                            <>Unsubscribed!</>
                        ) : (
                            <>
                                <Typography variant="body1" gutterBottom>
                                    If you'd like to unsubscribe from receiving personalized travel recommendations from Via Blue, please confirm below.
                                </Typography>
                                <Button variant="contained" color="primary" onClick={handleUnsubscribe} style={{ margin: '10px 0 0px' }}>
                                    Unsubscribe from All Emails
                                </Button>
                            </>
                        )}
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}

export default Unsubscribe;