// HomePage.js

import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function Help() {
    const location = useLocation();
    const [topicId, setTopicId] = useState('');

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        setTopicId(queryParams.get('topicId'));
    }, [location.search]); // Added location.search to the dependency array

    if (topicId === "94b53a17-6708-488d-9324-e4a9f80185cd") {
        const sentiments = [
            { Icon: SentimentDissatisfiedIcon, label: "I would not visit this even if I were nearby." },
            { Icon: SentimentNeutralIcon, label: "I might visit this if I were nearby, but I'm not sure." },
            { Icon: SentimentSatisfiedIcon, label: "I would probably visit this if I were nearby, but I wouldn't be disappointed if I couldn't go." },
            { Icon: SentimentSatisfiedAltIcon, label: "I would definitely visit this if I were nearby, and I'd be disappointed if I couldn't go." }
        ];

        return (
            <Grid container direction="column" spacing={2}>
                {sentiments.map(({ Icon, label }, index) => (
                    <Grid item key={index}>
                        <Box display="flex" flexDirection="column" alignItems="left">
                            <Icon className="sentiment-icon" />
                            <Typography variant="body1">{label}</Typography>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        );
    }

    // Return null or some default content if topicId doesn't match
    return null;
}

export default Help;