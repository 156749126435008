import React, { useState, useEffect, useRef } from 'react';
import Button from '@mui/material/Button'; // Assuming you're using Material-UI for buttons
import { useUser } from './UserContext';
import Api from './Api';
import Quiz from './Quiz';

const Reader = () => {
    const [tracks, setTracks] = useState([]);
    const [quiz, setQuiz] = useState(true);
    const [quizResponses, setQuizResponses] = useState([]);
    const { userId, userData } = useUser(); // Removed userData since it's not used
    const [currentTrackIndex, setCurrentTrackIndex] = useState(0);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(-1);
    const audioRef = useRef(null);

    useEffect(() => {
        const load = async () => {
            if (userData) {
                let quiz = userData.flags?.quiz;
                if (quiz === undefined) {
                    quiz = true;
                }
                setQuiz(quiz);

                let podcastFilter = {};
                if (userData.podcasts) {
                    podcastFilter = { id: { $in: userData.podcasts } };
                }
                let tracks = await Api.readCrudByQuery("podcasts", podcastFilter);
                if (quiz) {
                    let quizResponses = await Api.readCrudByQuery("quizResponses", { userId });
                    tracks = tracks.filter(track => !quizResponses.some(quizResponse => quizResponse.podcastId === track.id));
                    setQuizResponses(quizResponses);
                }
                tracks = tracks.filter(track => track.text);
                setTracks(tracks);
            }
        };

        load();
    }, [userId, userData]);

    useEffect(() => {
        const playAudio = () => {
            const audio = audioRef.current;
            if (!audio) return;

            let newSrc;
            if (currentQuestionIndex === -1) {
                newSrc = tracks[currentTrackIndex]?.url;
            } else {
                newSrc = tracks[currentTrackIndex]?.questions[currentQuestionIndex]?.url;
            }

            if (audio.src !== newSrc) {
                audio.src = newSrc;
                audio.play().catch(error => console.error("Playback failed:", error));
            }
        };

        if (tracks.length > 0) playAudio();
    }, [currentTrackIndex, currentQuestionIndex, tracks]);

    const handleNext = () => {
        if (currentQuestionIndex + 1 < tracks[currentTrackIndex]?.questions?.length) {

            const nextQuestionId = currentQuestionIndex + 1;
            const quiz = {
                "id": userId,
                timestamp: new Date().toISOString(),
                podcastId: tracks[currentTrackIndex].id,
                questionId: nextQuestionId,
                correctAnswer: tracks[currentTrackIndex].questions[nextQuestionId].correctAnswer,
            };
            Api.writeCrud("quizzes", "id", userId, quiz); // async

            setCurrentQuestionIndex(curr => curr + 1);
        } else if (currentTrackIndex + 1 < tracks.length) {
            setTimeout(() => {
                setCurrentTrackIndex(curr => curr + 1);
                setCurrentQuestionIndex(-1); // Reset question index for the new track
            }, 5000); // 5-second pause
        } else {
            // Optionally handle the end of the playlist, e.g., looping or stopping
            console.log("End of playlist");
        }
    };

    useEffect(() => {
        const audio = audioRef.current;
        if (!audio) return;

        const handleEnded = () => {
            handleNext();
        };

        audio.addEventListener('ended', handleEnded);
        return () => {
            audio.removeEventListener('ended', handleEnded);
        };
    }, [currentTrackIndex, currentQuestionIndex, tracks]);

    const handlePlay = () => {
        audioRef.current?.play();
    };

    const handlePause = () => {
        audioRef.current?.pause();
    };

    const handleSkip = () => {
        handleNext();
    };

    const selectTrack = (index) => {
        setCurrentTrackIndex(index);
        setCurrentQuestionIndex(-1); // Reset question index for the new track
    };

    return (
        <>
            {userId && userData && tracks.length > 0 && (
                <>
                    <audio ref={audioRef} preload="auto" />
                    <div style={{ padding: '20px' }}>
                        <Button variant="contained" color="primary" onClick={handlePlay}>Play</Button>
                        <Button variant="contained" color="secondary" onClick={handlePause}>Pause</Button>
                        {userId === "tim.grow@gmail.com" && <Button variant="contained" onClick={handleSkip}>Skip</Button>}
                    </div>
                    {quiz && tracks.length > 0 && <Quiz quizId={userId} />}
                    <div style={{ maxHeight: '150px', overflowY: 'auto', marginTop: '10px' }}>
                        {tracks.map((track, index) => (
                            <div key={index} onClick={() => selectTrack(index)} style={{ cursor: 'pointer', padding: '5px' }}>
                                {track.name}
                            </div>
                        ))}
                    </div>
                </>
            )}
        </>
    );
};

export default Reader;
