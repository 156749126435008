import React, { useState, useEffect, useRef } from 'react';
import Api from './Api';

const AudioPlayer = ({ tracks }) => {
    const [currentTrackIndex, setCurrentTrackIndex] = useState(0); // Start from the first track
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(-1);
    const audioRef = useRef(null);

    useEffect(() => {
        const audio = audioRef.current;
        let newSrc;

        // Determine the source based on the current track or question
        if (currentQuestionIndex === -1) {
            newSrc = tracks[currentTrackIndex].url;
        } else {
            newSrc = tracks[currentTrackIndex].questions[currentQuestionIndex].url;
        }

        // Update the audio source if it has changed
        if (audio.src !== newSrc) {
            audio.src = newSrc;
        }

        // Attempt to play the new source
        audio.play().catch(error => console.error("Playback failed:", error));

    }, [currentTrackIndex, currentQuestionIndex, tracks]);

    useEffect(() => {
        const audio = audioRef.current;

        const handleEnded = () => {
            if (currentQuestionIndex + 1 < tracks[currentTrackIndex].questions?.length) {
                // There are more questions; move to the next question
                Api.log("tim.grow@gmail.com", `Track: ${tracks[currentTrackIndex].id}`);
                const nextQuestionId = currentQuestionIndex + 1;
                const quiz = {
                    "id": "1",
                    timestamp: new Date().toISOString(),
                    podcastId: tracks[currentTrackIndex].id,
                    questionId: nextQuestionId,
                    correctAnswer: tracks[currentTrackIndex].questions[nextQuestionId].correctAnswer,
                };
                Api.writeCrud("quizzes", "id", "1", quiz); // async
                setCurrentQuestionIndex(curr => curr + 1);
            } else if (currentTrackIndex + 1 < tracks.length) {
                // Move to the next track and reset the question index after a brief pause
                setTimeout(() => {
                    setCurrentTrackIndex(curr => curr + 1);
                    setCurrentQuestionIndex(-1); // Reset question index for the new track
                }, 5000); // 5-second pause
            } else {
                // Last track and question; reset to the first track or handle as needed
                console.log("End of playlist");
            }
        };

        audio.addEventListener('ended', handleEnded);
        return () => audio.removeEventListener('ended', handleEnded);
    }, [currentTrackIndex, currentQuestionIndex, tracks]);

    // Handles user selection of a track
    const selectTrack = (index) => {
        setCurrentTrackIndex(index);
        setCurrentQuestionIndex(-1); // Reset question index for the new track
    };

    return (
        <div>
            <audio ref={audioRef} controls />
            <div style={{ maxHeight: '150px', overflowY: 'auto', marginTop: '10px' }}>
                {tracks.map((track, index) => (
                    <div key={index} onClick={() => selectTrack(index)} style={{ cursor: 'pointer', padding: '5px' }}>
                        {track.name}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default AudioPlayer;
