import React, { useState } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import Api from './Api.js';

const CreateAccount = ({ login }) => {
	const [email, setEmail] = useState('');
	const [step, setStep] = useState(0);

	const handleChange = (event) => {
		setEmail(event.target.value);
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		await Api.createUser(email);
		if (login) {
			await login(email);
		}
		setStep(1);
	};

	return (
		<React.Fragment>
			{(step === 0) && (
				<React.Fragment>
					Create a free account to view more personalized travel recommendations:
					<form noValidate onSubmit={handleSubmit}>
						<TextField
							required
							id="email"
							label="Email Address"
							type="email"
							name="email"
							fullWidth
							margin="normal"
							value={email}
							onChange={handleChange}
						/>
						<Button variant="contained" color="primary" type="submit">
							Submit
						</Button>
					</form>
				</React.Fragment>
			)}
			{(step === 1) && (
				<div>
					Thanks! We'll be in touch
				</div>
			)}
		</React.Fragment>
	);
};

export default CreateAccount;
