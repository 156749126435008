// Admin.js
import React, { useState, useEffect } from 'react';
import Api from "./Api";
import CreateAccount from "./CreateAccount";
import { useUser } from './UserContext';
import Cookies from 'js-cookie';

const Destinations = () => {
	const [data, setData] = useState([]);

	useEffect(() => {
		const load = async () => {
			setData(await Api.readCrudByQuery("destinations", {}));
		};

		load();
	}, []);

	return (
		<>
			{data.map(destination => (
				<React.Fragment key={destination.id}>
					<a href={`/destination?destinationId=${destination.id}`}>
						{`${destination.experimentName ? `${destination.experimentName}: ` : ''}${destination.name}`}
					</a>
					<br />
				</React.Fragment>
			))}
		</>
	);
};

const AdminLogin = () => {
	const { userId, setUserId } = useUser();
	const [emailAddress, setEmailAddress] = useState('');

	useEffect(() => {
		const fetchEmailAddress = async () => {
			if (userId) {
				const user = (await Api.readCrudByQuery("users", { "userId": userId }))[0];
				setEmailAddress(user.emailAddress);
			}
		};

		fetchEmailAddress();
	}, [userId]);

	// TODO: Refector to Login.js
	const login = async (emailAddress) => {
		const user = (await Api.readCrudByQuery("users", { "emailAddress": emailAddress }))[0];
		if (user) {
			setUserId(user.userId);
			Cookies.set('userId', user.userId, { expires: 10 * 365 });
		}
	};

	const handleLogout = (e) => {
		e.preventDefault();
		setUserId(null);
		setEmailAddress(null);
		Cookies.remove('userId');
	};

	if (userId) {
		if (emailAddress) {
			return (
				<>
					{emailAddress}<br />
					<a href="/#" onClick={handleLogout}>Logout</a >
				</>
			);
		} else {
			return null;
		}
	} else {
		return <CreateAccount login={login} />;
	}
};

const Admin = () => {
	const [state, setState] = useState('menu');

	const handleClick = (newState) => (e) => {
		e.preventDefault();
		setState(newState);
	};

	if (state === 'destinations') {
		return <Destinations />;
	} else if (state === 'login') {
		return <AdminLogin />;
	}
	return (
		<>
			<a href="/#" onClick={handleClick('destinations')}>Destinations</a ><br />
			<a href="/#" onClick={handleClick('login')}>Login</a >
		</>
	);
};

export default Admin;
