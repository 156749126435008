import './AffiliateLink.css'; // Importing CSS for styling

function AffiliateLink() {
  // Replace with your actual affiliate URL
  const affiliateUrl = 'https://expedia.com/affiliates/hotel-search-new-york.76rXqRK';
    
  return (
    <div className="affiliate-link-container">
      <a href={affiliateUrl} target="_blank" rel="noopener noreferrer" className="affiliate-link">
        View Hotel Deals
      </a>
    </div>
  );
}

export default AffiliateLink;
