// Itinerary.js

import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Api from './Api';
import { Container, Typography, List, ListItem, ListItemText, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useUser } from './UserContext';

const Itinerary = () => {
    const location = useLocation();
    const [itineraries, setItineraries] = useState(null);
    const { userId, userData } = useUser();

    useEffect(() => {
        const load = async () => {
            const itineraryId = (new URLSearchParams(location.search)).get("id");
            const version = (new URLSearchParams(location.search)).get("version");
            let itinerary;
            if (itineraryId) {
                if (version) {
                    itinerary = await Api.readItinerary(itineraryId, version); // TODO
                } else {
                    itinerary = await Api.readItinerary(itineraryId);
                }
            } else if (userData) {
                const itineraryId = userData.itineraries[0]; // TODO: What if there are multiple itineraries?
                itinerary = await Api.readItinerary(itineraryId);
            }
            if (itinerary) {
                setItineraries([itinerary]);
            }
        }

        load();
    }, [userData, location.search]);

    return (
        <Container maxWidth="sm">
            {itineraries && itineraries.map((itinerary, index) => (
                <Accordion key={index} defaultExpanded={false}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h6">{itinerary.name}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {itinerary.schedule.map((day, dayIndex) => (
                            <div key={dayIndex}>
                                <Typography variant="subtitle1" gutterBottom>
                                    <b>{day.date}</b>
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    {day.description}
                                </Typography>
                                <List dense>
                                    {day.activities.map((activity, activityIndex) => (
                                        <ListItem key={activityIndex} style={{ alignItems: "flex-start" }}>
                                            <ListItemText
                                                primary={<>
                                                    <Typography component="span" variant="body2" >
                                                        {`${activity.name} (${activity.duration} minutes)`}
                                                    </Typography>
                                                    {activity.comment && (
                                                        <Typography component="span" variant="body2" style={{ display: 'block', fontStyle: 'italic', fontSize: 'smaller' }}>
                                                            {activity.comment}
                                                        </Typography>
                                                    )}
                                                </>}
                                            />
                                        </ListItem>
                                    ))}
                                    <ListItem key="overnightAccommodation">
                                        <ListItemText primary={`Sleep: ${day.overnightAccommodation}`} />
                                    </ListItem>
                                </List>
                            </div>
                        ))}
                    </AccordionDetails>
                </Accordion>
            ))}
        </Container>
    );
};

export default Itinerary;