import './App.css';
import AffiliateLink from './components/AffiliateLink';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './HomePage';
import ResponsePage from './ResponsePage';

function Activity({ time, title, description }) {
  return (
    <div className="activity">
      <h3>{time}</h3>
      <h2>{title}</h2>
      <p>{description}</p>
    </div>
  );
}

function App2() {
  return (
    <div className="App">
      <header>
        <h1>New York City Holiday Itinerary</h1>
      </header>
      <main>
        {/* Day 1: Evening Arrival */}
        <h2>Day 1: Evening Arrival</h2>
        <Activity 
          time="5:00 PM" 
          title="Check-in at a Cozy Boutique Hotel" 
          description="Settle into a charming hotel in Midtown Manhattan. Enjoy a welcome drink and savor the festive ambiance of the city." 
        />
        <Activity 
          time="6:30 PM" 
          title="Stroll along Fifth Avenue" 
          description="Witness the magical holiday window displays at iconic stores like Saks Fifth Avenue and Bergdorf Goodman." 
        />
        <Activity 
          time="8:00 PM" 
          title="Dinner at a Classic New York Restaurant" 
          description="Indulge in a sumptuous meal at a renowned restaurant." 
        />
        <Activity 
          time="10:00 PM" 
          title="Broadway Show" 
          description="Experience the magic of Broadway with a holiday-themed performance." 
        />

        {/* Day 2: Full Day of Exploration */}
        <h2>Day 2: Full Day of Exploration</h2>
        <Activity 
          time="8:00 AM" 
          title="Breakfast at a Local Cafe" 
          description="Start your day with a hearty breakfast at a quintessential New York cafe." 
        />
        <Activity 
          time="9:30 AM" 
          title="Ice Skating at Rockefeller Center" 
          description="Glide on the iconic ice rink at Rockefeller Center, surrounded by the towering Christmas tree and glittering decorations." 
        />
        <Activity 
          time="11:30 AM" 
          title="Visit Bryant Park Winter Village" 
          description="Explore the festive market stalls and enjoy a hot chocolate. Shop for unique holiday gifts." 
        />
        <Activity 
          time="1:00 PM" 
          title="Lunch at The Plaza Food Hall" 
          description="Enjoy a diverse array of gourmet foods in this upscale, European-inspired food hall." 
        />
        <Activity 
          time="2:30 PM" 
          title="Central Park Winter Walk" 
          description="Take a leisurely stroll through Central Park. The snow-covered landscapes provide a perfect contrast to the bustling city streets." 
        />
        <Activity 
          time="4:30 PM" 
          title="Top of the Rock Observation Deck" 
          description="Head to the Top of the Rock for breathtaking views of the city skyline as the sun sets." 
        />
        <Activity 
          time="6:00 PM" 
          title="Dinner and Jazz in Greenwich Village" 
          description="Conclude your trip with dinner at a cozy Greenwich Village restaurant, followed by a live jazz performance." 
        />
	<AffiliateLink />
      </main>
      <footer>
        {/* Footer content */}
      </footer>
    </div>
  );
}

export default App2;
