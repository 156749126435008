// HomePage.js

import React, { useState, useEffect } from 'react';
import { useUser } from './UserContext';
import Api from './Api';
import { useNavigate } from 'react-router-dom';

import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import CardFeed from './CardFeed';

function HomePage() {
	const [destinations, setDestinations] = useState([]);
	const { userId, userData, sessionId } = useUser();

	const navigate = useNavigate();

	const handleClick = (card) => {
		navigate(`/destination?destinationId=${card.id}`);
	};

	useEffect(() => {
		const loadDestinations = async () => {
			if (userId) {
				const destinations = await Api.loadDestinations(userId);
				setDestinations(destinations);
			}
		};

		loadDestinations();
		Api.log(sessionId, window.location.href);
	}, [userId]);

	return (
		<React.Fragment>
			<CardFeed cards={destinations} handleClick={handleClick} expandedByDefault={true} />

		</React.Fragment>
	);
}

export default HomePage;
