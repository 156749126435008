import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Menu from './Menu.js';
import HomePage from './HomePage.js';
import ResponsePage from './ResponsePage.js';
import Fact from './Fact.js';
import Destination from './Destination.js';
import Trip from './Trip.js';
import Profile from './Profile.js';
import Timesheet from './Timesheet.js';
import Redirector from './Redirector.js';
import { UserProvider } from './UserContext';
import Wrapper from "./Wrapper";
import Admin from "./Admin";
import ImageSwiper from "./ImageSwiper.js";
import Login from "./Login.js";
import Error from "./Error.js";
import Help from "./Help.js";
import Itinerary from "./Itinerary.js";
import Audio from "./Audio.js";
import Quiz from "./Quiz.js";
import Reader from "./Reader.js";
import Reader2 from "./Reader2.js";
import CustomTrip from "./CustomTrip.js";
import Offer from "./Offer.js";
import EmailPreferences from "./EmailPreferences.js";
import EmailDraft from './EmailDraft.js';
import UXR from './UXR.js';

function App() {
	return (
		<BrowserRouter>
			<UserProvider>
				<Routes>
					<Route path="/" element={<Wrapper banner={true}><HomePage /></Wrapper>} />
					<Route path="/response" element={<ResponsePage />} />
					<Route path="/fact" element={<Fact />} />
					<Route path="/menu" element={<Menu />} />
					<Route path="/destination" element={<Wrapper><Destination /></Wrapper>} />
					<Route path="/trip" element={<Trip />} />
					<Route path="/admin" element={<Admin />} />
					<Route path="/timesheet" element={<Timesheet />} />
					<Route path="/profile" element={<Profile />} />
					<Route path="/login" element={<Login />} />
					<Route path="/error" element={<Error />} />
					<Route path="/help" element={<Help />} />
					<Route path="/itinerary" element={<Itinerary />} />
					<Route path="/audio" element={<Audio />} />
					<Route path="/quiz" element={<Wrapper><Quiz /></Wrapper>} />
					<Route path="/reader" element={<Wrapper><Reader /></Wrapper>} />
					<Route path="/reader2" element={<Wrapper><Reader2 /></Wrapper>} />
					<Route path="/custom" element={<Wrapper><CustomTrip /></Wrapper>} />
					<Route path="/offer" element={<Wrapper><Offer /></Wrapper>} />
					<Route path="/email" element={<Wrapper><EmailPreferences /></Wrapper>} />
					<Route path="/draft" element={<Wrapper><EmailDraft /></Wrapper>} />
					<Route path="/uxr" element={<Wrapper><UXR /></Wrapper>} />
				</Routes>
			</UserProvider>
		</BrowserRouter>
	);
}

export default App;
