import React, { useState, useEffect } from 'react';
import { Box, Button, TextField } from '@mui/material';
import Config from "./Config.js";

// TODO: What happens if you click 'Another' before next loads?

class Fact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
	    fact: "",
	    nextFact: "",
	    topic: "",
	    chatId: "",
	    searchText: ""
	};
    }

    fetch = async (key, topic, chatId, feedback) => {
	console.log("fetching " + key);
        try {
	    const url = Config.API_URL + "?op=fact&topic=" + topic + "&chatId=" + chatId;
	    if (feedback)
		url = url + "&feedback=" + feedback;
	    console.log("url: " + url);
            const response = await fetch(url);
            const data = await response.json();
	    console.log("setting " + key + ": " + data.response);
            this.setState({ [key]: data.response });
        } catch (error) {
            console.error(error);
        }
    }

    handleSubmit = async (e) => {
	e.preventDefault();

 	if ((this.state.nextFact !== this.state.fact) && (this.state.nextFact !== "")) {
	    const nextFact = this.state.nextFact;
	    this.setState({ fact: nextFact });
	    this.fetch("nextFact", this.state.topic, this.state.chatId);
	}
   }
    

    handleSearchSubmit = (e) => {
        e.preventDefault();

	const _topic = this.state.searchText;
	const _chatId = Date.now().toString();
	this.setState({ topic: _topic });
	this.setState({ chatId: _chatId });
	this.setState({ nextFact: "" });
	this.fetch("fact", _topic, _chatId);
	this.fetch("nextFact", _topic, _chatId);
    }

    handleSearchChange = (e) => {
        this.setState({ searchText: e.target.value });
    }

    componentDidMount() {
    }

    render() {
        return (
            <Box padding={3}>
                <form noValidate onSubmit={this.handleSearchSubmit} style={{ marginBottom: '30px' }}>
                    <TextField
                        label="Search"
                        variant="outlined"
                        fullWidth
                        onChange={this.handleSearchChange}
                    />
                </form>

		    {this.state.fact}
		    <div style={{ marginBottom: '20px' }} />
		{this.state.fact && (
		    <form noValidate onSubmit={this.handleSubmit}>
			<Button variant="contained" color="primary" type="submit">
			    Another
			</Button>
		    </form>
		)}
            </Box>
        );
    }
}

export default Fact;
