import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Card, CardContent } from '@mui/material';
import Config from "./Config.js";
import TaskQueue from "./TaskQueue.js";
import Cookies from 'js-cookie';
import "./ViaBlue.css";
import "./Trip.css";

class Trip extends React.Component {
	constructor(props) {
		super(props);
		this.userId = "";
		this.fact = null;
		this.nextFact = null;
		this.chatId = "";
		this.taskQueue = new TaskQueue();
		this.state = {
			searchText: "",
			destionation: "",
			fact: "",
			loading: false
		};
	}

	fetchFromApi = async (url, data) => {
		console.log("request: " + url);
		if (data !== null) { console.log("data: " + JSON.stringify(data)); }
		const response = await (await fetch(url, {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: data !== null ? JSON.stringify(data) : null,
		})).json();
		console.log("response " + JSON.stringify(response));
		return response;
	}

	// TODO: clear nextFact (for 2nd search)
	startTrip = async (destination, chatId) => {
		const url = Config.API_URL + "?op=trip&op2=startTrip&userId=" + this.userId + "&destination=" + destination + "&chatId=" + chatId;
		const f = await this.fetchFromApi(url, null);
		this.fact = f;
		this.setState({
			loading: false,
			fact: f.description
		});
	}

	continueTripTask = async (feedback) => {
		try {
			const url = Config.API_URL + "?op=trip&op2=continueTrip&userId=" + this.userId + "&destination=" + this.destination + "&chatId=" + this.chatId;
			const f = await this.fetchFromApi(url, feedback);
			if (this.state.loading) {
				this.fact = f;
				this.setState({
					loading: false,
					fact: f.description
				});
			} else {
				this.nextFact = f;
			}
		} catch (error) {
			console.error(error);
		}
	}

	handleSearchSubmit = async (e) => {
		try {
			e.preventDefault();

			this.destination = this.state.searchText;
			this.chatId = Date.now().toString();
			this.setState({ loading: true });
			await this.startTrip(this.state.searchText, this.chatId);

			this.taskQueue.enqueue(() => this.continueTripTask());
		} catch (error) {
			console.error(error);
		}
	}

	handleFeedbackClick = async (buttonIdentifier) => {
		try {
			if (!this.state.loading) {
				const feedback = {
					"version": "0.20240119",
					"userId": this.userId,
					"name": this.fact.identifier,
					"rating": buttonIdentifier
				};

				if (this.nextFact != null) {
					this.fact = this.nextFact;
					this.nextFact = null;
					this.setState({ fact: this.fact.description });
				} else {
					this.setState({ loading: true });
				}

				this.taskQueue.enqueue(() => this.continueTripTask(feedback));
			}
		} catch (error) {
			console.error(error);
		}
	}

	loadDestinations = async () => {
		const url = Config.API_URL + "?op=trip&op2=destinations&userId=" + this.userId;
		const destinations = await (await fetch(url)).json();
		console.log("destinations: " + destinations);
		this.setState({ destination: destinations[0] });
	}

	componentDidMount() {
		this.userId = Cookies.get('userId');
		console.log("userId: " + this.userId);

		this.loadDestinations();
	}

	render() {
		return (
			<div className="mobile-container">
				<Box padding={3}>
					<form noValidate onSubmit={this.handleSearchSubmit} style={{ marginBottom: '30px' }}>
						<TextField
							label="Search"
							variant="outlined"
							fullWidth
							onChange={(e) => this.setState({ searchText: e.target.value })}
						/>
					</form>
					{this.state.destination && (
						<React.Fragment>
							<div>{this.state.destination.name}</div>
							<div>{this.state.destination.description}</div>
						</React.Fragment>
					)}
					{this.state.fact && (
						<React.Fragment>
							<div>{this.state.fact}</div>
							<div style={{ marginBottom: '20px' }} />
						</React.Fragment>
					)}
					{this.state.loading && <p>Loading...</p>}
				</Box>
			</div>
		);
	}
}

export default Trip;
