import React, { useState, useEffect } from 'react';
import { Box, Button, TextField } from '@mui/material';
import Config from "./Config.js";
import TaskQueue from "./TaskQueue.js";
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral';
import "./Trip.css";

class Profile extends React.Component {
    constructor(props) {
	super(props);
	this.userId = "";
	this.state = {
	    textContent: ""
	}
    }

    loadProfile = async () => {
	const url = Config.API_URL + "?op=crud&collection=user_profiles&key=userId&value=" + this.userId;
        const response = await (await fetch(url)).json();
	this.setState({ textContent: response.profile });
    }
    
    componentDidMount() {
	const params = new URLSearchParams(window.location.search);
	this.userId = params.get("userId");	
	this.loadProfile();
    }

    handleTextChange = (e) => {
	this.setState({ textContent: e.target.value });
    }
    
    handleClick = async () => {
	const url = Config.API_URL + "?op=crud&collection=user_profiles&key=userId&value=" + this.userId;
        const response = await fetch(url, {
	    method: "POST",
	    headers: { "Content-Type": "application/json" },
	    body: JSON.stringify({
		userId: this.userId,
		profile: this.state.textContent
	    })
	});
    }
    
    render() {
	return (
	    <div className="mobile-container">
		<Box padding={3}>
		    <textarea
			style={{ width: '100%', height: '300px' }}
			value={this.state.textContent}
			onChange={this.handleTextChange}
		    />
		</Box>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={this.handleClick}
                    style={{ marginTop: '20px' }} // Add some margin to the top
                >
                Save
                </Button>
	    </div>
	);	
    }
}

export default Profile;
