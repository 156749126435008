import { isAfter, differenceInMinutes, parse, format} from 'date-fns';

class TimesheetHelper {
    static calculateDurations(input) {
	const dayStart = parse('6:00', 'H:mm', new Date());
	const dayEnd = parse('21:00', 'H:mm', new Date());
	const data = input.trim().split('\n').map(entry => {
	    const [time, category] = entry.split(': ');
	    return { time: parse(time, 'H:mm', new Date()), category };
	});

	// Add start and end of day
	data.unshift({ time: dayStart, category: 'T' });
	data.push({ time: dayEnd, category: '' });

	const durations = data.reduce((acc, entry, index, array) => {
	    if (index === 0) return acc;
	    const prevEntry = array[index - 1];
	    const duration = differenceInMinutes(entry.time, prevEntry.time);
	    acc[prevEntry.category] = (acc[prevEntry.category] || 0) + duration;
	    return acc;
	}, {});

	for (const category in durations) {
	    durations[category] = Math.round(durations[category] * 100) / 100;
	}
	
	return durations;
    }

    static round(durations) {
	for (const key in durations) {
	    durations[key] = Math.round(durations[key] / 15) / 4;
	}
	return durations;
    }
}

export default TimesheetHelper;
