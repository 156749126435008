import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Api from './Api'; // Make sure Api is correctly imported
import { useUser } from './UserContext';

const Quiz = ({ quizId = "1" }) => {
    const { userId, userData } = useUser();
    const [emailAddress, setEmailAddress] = useState(null);
    const [correctAnswers, setCorrectAnswers] = useState(null);
    const [open, setOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');

    useEffect(() => {
        const fetchEmailAddress = async () => {
            if (userId) {
                const user = (await Api.readCrudByQuery("users", { "userId": userId }))[0];
                setEmailAddress(user.emailAddress);
            }
        };

        const fetchCorrectAnswers = async () => {
            if (userId) {
                const quizResponses = await Api.readCrudByQuery("quizResponses", { userId });
                // correctResponses is an array of responses where answer === correctAnswer
                const correctResponses = quizResponses.filter(response => response.answer === response.correctAnswer);
                setCorrectAnswers(correctResponses.length);
            }
        };

        fetchEmailAddress();
        fetchCorrectAnswers();
    }, [userId]);

    const handleButtonClick = async (buttonLabel) => {
        Api.log(userId, `${buttonLabel} button clicked`);
        let quizResponse = {
            quizId: quizId,
            userId: userId,
            answer: ['A', 'B', 'C', 'D'].indexOf(buttonLabel),
            timestamp: new Date().toISOString()
        };
        quizResponse = await Api.writeQuizResponse(quizResponse);
        setCorrectAnswers(quizResponse.correctResponses); // TODO: rename to correctAnswers
        console.log(JSON.stringify(quizResponse, null, 2));
        setDialogMessage(`You submitted ${buttonLabel}`);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            {emailAddress && <h3>{emailAddress}</h3>}
            {correctAnswers && <div>{correctAnswers}</div>}
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {['A', 'B', 'C', 'D'].map((buttonLabel) => (
                    <Button
                        key={buttonLabel}
                        variant="contained"
                        onClick={() => handleButtonClick(buttonLabel)}
                        sx={{ m: 1, width: '100%', height: 60 }}
                    >
                        {buttonLabel}
                    </Button>
                ))}
            </div>
            {/* Dialog Component */}
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{dialogMessage}</DialogTitle>
                <DialogActions>
                    <Button onClick={handleClose}>Dismiss</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default Quiz;
