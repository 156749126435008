import React, { useState } from 'react';
import { Box, Button, TextField, Typography, AppBar, Toolbar } from '@mui/material';
import './ViaBlue.css'; // Import ViaBlue.css file
import { Link, useNavigate } from 'react-router-dom';

const Wrapper = ({ children, banner = false }) => {
	const navigate = useNavigate();

	return (
		<div className="mobile-container">
			<Box padding={2}>
				<Box sx={{ textAlign: 'left', color: 'blue' }}>
					<Typography variant="h4" gutterBottom sx={{ color: 'primary.main', fontWeight: 800 }}>
						<Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>Via Blue</Link>
					</Typography>
				</Box>
				{children}
			</Box>
			{banner && (
				<div className="banner-container">
					<AppBar position="fixed" color="primary" sx={{ top: 'auto', bottom: 0, cursor: 'pointer' }}>
						<Toolbar sx={{ paddingLeft: 2, paddingRight: 2 }}>
							<Typography variant="h6" sx={{ color: 'inherit', paddingRight: 2 }}>
								Ready to go?
							</Typography>
							<Box sx={{ flexGrow: 1 }} />
							<Button
								color="inherit"
								variant="outlined"
								onClick={() => navigate('/offer')}
								sx={{ whiteSpace: 'nowrap', paddingLeft: 3, paddingRight: 3 }}>  {/* Increased padding on the left and right */}
								Plan a trip
							</Button>
						</Toolbar>
					</AppBar>
				</div>
			)
			}
		</div >
	);
};

export default Wrapper;
