import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import Api from './Api';
import { useUser } from './UserContext';

const Login = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { userId, setUserId } = useUser();

    useEffect(() => {
        const fetchUserIdAndLogin = async () => {
            const queryParams = new URLSearchParams(location.search);
            const loginToken = queryParams.get('loginToken');

            if (!loginToken) {
                Api.error('No login token provided');
                navigate('/error');
                return;
            }

            try {
                const user = (await Api.readCrudByQuery('users', { loginToken: loginToken }))[0];

                if (user && user.userId) {
                    Cookies.set('userId', user.userId, { expires: 10 * 365 });
                    setUserId(user.userId);

                    navigate('/');
                } else {
                    Api.error('Invalid token or no userId returned');
                    navigate('/error');
                }
            } catch (error) {
                Api.error('Error validating token:', error);
                navigate('/error');
            }
        };

        fetchUserIdAndLogin();
    }, [location, navigate]);

    return null;
};

export default Login;
